.hr {
  width: 100%;
  &-10 {
    @extend .hr;
    margin: 10px 0;
  }
  &-5 {
    @extend .hr;
    margin: 5px 0;
  }
  &-3 {
    @extend .hr;
    margin: 3px 0;
  }
}
.bg {
  &-white {
    background: $white !important;
  }
}
.br-bor {
  &--int {
    width: 100%;
    border-bottom: 1px solid $intborder;
  }
}

.t-border {
  &-px5 {
    display: block;
    background: $theme;
    width: 100%;
    height: 5px;
  }
}

.color {
  &-pink {
    color: $pink !important;
  }
  &-red {
    color: $radishpink !important;
  }
}

.mr {
  &-zero {
    margin: 0 !important;
  }
  &-md {
    @include bp($mob) {
      &-auto {
        margin: auto;
      }
    }
  }
}

.jsc {
  justify-content: center;
}
