input {
  font-family: "Satoshi" !important;
}
.search-main {
  display: flex;
  flex-direction: column;
  // height: calc(100vh - 110px);
  padding-bottom: 15px;
  .search-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .search-left {
      display: flex;
      align-items: center;
      .search-box {
        // border-radius: 4px;
        // border:  1px solid #005287;
        // background-color: #fff;
        .MuiInputBase-root {
          padding-left: 0px;
          border-radius: 10px;
          width: 476px;
          height: 40px;
          background-color: white;
          // border: 1px solid #d1d9e0;
        }
        .search_icon {
          margin-left: 20px;
          svg {
            stroke: none;
          }
        }
      }
      span {
        color: #8e8e8e;
        font-size: 16px;
        font-weight: 500;
        word-wrap: break-word;
        margin-left: 15px;
        text-transform: capitalize;
      }
    }
    .MuiInputBase-input {
      padding: 5px 15px;
      padding-left: 0px;
      // Representation & Warranties
      color: #606060;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      word-wrap: break-word;
      text-overflow: ellipsis;
      letter-spacing: 0.8px;
      line-height: 24px;
      background-color: white;
      &::placeholder {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.8px;
      }
    }
    fieldset {
      border-color: #d1d9e0;
    }
    .group-control {
      width: 430px;
    }
  }
  .searchtab_content {
    // flex: 1;
    height: calc(100vh - 150px);
    overflow: auto;
    background-color: white;
    border-radius: 15px 0px 0px 0px;
    border: 1px solid #e4e4e4;
    >div.MuiBox-root {
      height: 100%;
    }
  }
}
.search-result-head {
  margin-top: 12px;
  >ul {
    display: flex;
    align-items: center;
    >li {
      color: black;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.6px;
      word-wrap: break-word;
      margin-right: 20px;
      position: relative;
      &:last-child {
        &::after {
          content: unset;
        }
      }
      &::after {
        content: "";
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #000;
        position: absolute;
        right: -11px;
        top: 0px;
        bottom: 0;
        margin: auto;
      }
      a {
        color: #0098ee;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.6px;
        word-wrap: break-word;
        text-decoration: none;
      }
    }
  }
  .filters-main {
    margin-top: 12px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
    .filter-row {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      width: calc(100% - 163px);
      .async-select__control {
        height: 36px;
        border-color: #b3b3b3 !important;
        box-shadow: none !important;
        .async-select__placeholder {
          // Contract Type
          color: #505050;
          font-size: 12px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: normal;
        }
      }
      .filter-col {
        width: auto;
        max-width: 190px;
        min-width: 151px;
        .MuiOutlinedInput-root {
          border-radius: 10px;
          background-color: white;
          color: #606060;
          fieldset {
            border: 1px solid #D1D9E0;
            letter-spacing: normal;
          }
        }
        input {
          color: #606060 !important;
          &::placeholder {
            font-size: 12px;
            color: #000;
            color: #606060 !important;
          }
        }
        .rs-picker-default.rs-picker-toggle-wrapper {
          .rs-picker-input-group {
            border-color: #b3b3b3;
            border-radius: 4px;
            font-size: 12px;
            z-index: 0;
            height: 40px;
            border-radius: 10px;
            background-color: #fff !important;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-family: 'Satoshi';
            color: #505050 !important;
            font-size: 14px;
            border: 1px solid #D1D9E0;
            span {
              color: #505050 !important;
            }
            .rs-picker-toggle-caret {
              font-size: 20px;
            }
          }
        }
        .rs-input {
          color: #333333 !important;
          font-size: 14px !important;
          &::placeholder {
            color: #333333 !important;
            font-family: 'Satoshi';
            font-size: 14px !important;
          }
        }
      }
    }
    .sort-by {
      width: 163px; // margin-left: 30px;
      .filter-row {
        width: 100%;
        .filter-col {
          min-width: 163px;
        }
      }
    }
  }
  .selected-filter {
    max-width: 980px;
    margin-top: 12px;
    margin-bottom: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    >ul {
      max-width: 80%;
      display: flex;
      overflow-y: hidden;
      overflow-x: auto;
      // flex-wrap: wrap;
      gap: 10px;
      li {
        border-radius: 4px;
        display: flex;
        align-items: flex-start;
        gap: 3px;
        margin-bottom: 0;
        ul {
          display: flex;
          // flex-wrap: wrap;
          gap: 10px;
        }
        h6 {
          font-size: 14px;
          color: #303030;
          line-height: 28px;
        }
        .selected {
          // Product
          color: #005287;
          font-size: 12px;
          font-weight: 500;
          line-height: 12px;
          word-wrap: break-word;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #e9f0fc;
          height: 28px;
          padding: 8px 8px;
          border-radius: 4px;
          width: max-content;
          border-radius: 10px;
          span {
            margin-right: 5px;
            display: block;
            font-size: 12px;
            color: #216dde;
          }
          svg {
            cursor: pointer;
            width: 20px;
            height: 15px;
          }
        }
      }
      &::-webkit-scrollbar {
        display: none;
      }
      // For Firefox
      scrollbar-width: none;
      // For IE, Edge
      -ms-overflow-style: none;
    }
    button {
      margin-left: 10px;
      width: 80px;
      min-width: 80px;
      height: 27px;
    }
    .selected-filter-scroll-button {
      width: 28px;
      height: 28px;
      background-color: white;
      border: 1px solid #d1d9e0;
      margin: 0px !important;
      min-width: 28px !important;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.search-result-body {
  // background-color: #ffffff;
  padding: 0x;
  // border: 1px solid #e4e4e4;
  // padding: 20px;
  overflow: hidden;
  display: flex;
  // flex-grow: 1;
  height: 100%;
  width: 100%;
  .left {
    width: 100%;
    // padding-right: 10px;
    overflow: hidden;
    height: 100%;
    // overflow: auto;
    >ul>li {
      padding: 20px;
      position: relative;
      border-left: 0;
      border-right: 0;
      border: 1px solid #d1d9e0;
      border-left: 0;
      // border-right: 0;
      &.selected {
        background: #ecfcff;
        border-radius: 0;
      }
      span {
        &.remove {
          position: absolute;
          right: 2px;
          top: 3px;
        }
      }
    }
  }
  ul {
    list-style: none;
    overflow: auto;
    li {
      cursor: pointer;
      // margin-bottom: 12px;
      .title {
        display: flex;
        align-items: center;
        .number {
          color: #303030;
          font-size: 16px;
          font-weight: 600;
          word-wrap: break-word;
        }
        h5 {
          color: #303030;
          font-size: 20px;
          word-wrap: break-word;
          // margin-left: 8px;
          text-transform: capitalize;
          color: #216dde;
          font-weight: 700;
        }
      }
      .doc_content {
        // padding-left: 17px;
        .tags-main {
          margin-top: 10px;
          ul {
            display: flex;
            flex-wrap: wrap;
            list-style-type: none;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: inline-block;
            vertical-align: top;
            width: -webkit-fill-available;
            li {
              margin-bottom: 0;
              // border-right: 2px solid #71a1ea;
              margin-right: 5px;
              padding-right: 5px;
              display: inline;
              position: relative;
              &:after {
                content: "";
                right: -1px;
                top: 2px;
                bottom: 0;
                width: 1.5px;
                height: 13px;
                background-color: #71a1ea;
                display: block;
                position: absolute;
                margin: auto;
              }
              &:last-child {
                &::after {
                  content: unset;
                }
              }
              p {
                font-size: 14px !important;
                font-weight: 400;
                line-height: normal;
                display: inline;
                b {
                  font-weight: 700;
                }
              }
              &:last-child {
                border-right: none;
              }
              p {
                margin-bottom: 0;
                font-size: 12px;
              }
            }
          }
        }
      }
      .description {
        margin-top: 12px;
        line-height: 22px;
        max-height: 66px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        font-size: 14px;
        p {
          color: #303030;
          font-weight: 400;
          margin: 0;
          word-wrap: break-word;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          display: flex;
          font-size: 16px;
          // * {
          //   text-overflow: ellipsis;
          //   -webkit-box-orient: vertical;
          //   -webkit-line-clamp: 1;
          //   overflow: hidden;
          //   display: -webkit-inline-box;
          //   width: auto;
          // }
          // b {
          //   white-space: nowrap;
          //   overflow: unset;
          //   margin: 0 2px;
          // }
          div {
            b {
              background-color: #ffe070;
            }
          }
        }
      }
      .definitions {
        margin-top: 12px;
        div {
          color: #505050;
          font-size: 14px;
          font-weight: 400;
          word-wrap: break-word;
          margin: 0;
          width: 100%;
          line-height: 21px;
          max-height: 66px;
          /* background-color: gainsboro; */
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
        }
      }
    }
  }
}
.left.preview-open {
  width: 45%;
  overflow: hidden;
  >ul {
    // padding-right: 10px;
  }
}
.right {
  width: 55%;
  border-left: 1px solid #ccc;
  display: flex;
  height: 100%;
  flex-direction: column;
  position: relative;
  &_contracttitle {
    text-align: center;
    padding: 5px 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  #react-doc-viewer {
    flex: 1;
  }
  >div:first-child {
    border-bottom: 1px solid #d1d9e0;
    padding: 4.5px 5px;
  }
  // .client_modal {
  //   position: absolute;
  //   right: 0;
  //   left: 0;
  //   display: flex;
  //   height: 100%;
  //   width: 100%;
  //   align-items: center;
  //   background-color: #00000050;
  //   &_list {
  //     background-color: #fff;
  //     width: calc(100% - 30px);
  //     max-height: 400px;
  //     overflow: auto;
  //     padding: 10px;
  //     margin: 0 auto;
  //     label {
  //       margin: 0;
  //       width: 100%;
  //       justify-content: space-between;
  //     }
  //   }
  // }
}
.MuiTabs-flexContainer {
  position: relative;
}
.head-right {
  position: absolute;
  right: 0;
  ul {
    display: flex;
    align-items: center;
    li {
      margin: 0 8px;
      .preview {
        // Preview
        font-size: 13px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0.24px;
        word-wrap: break-word;
        background-color: #ffffff;
        color: #404040;
        height: 30px;
        padding: 0 10px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: 1px solid #d1d9e0;
        svg {
          margin-right: 5px;
          height: 20px;
          width: 20px;
        }
        &.active {
          border: 1px solid #6472db;
          color: white;
          background-color: #6472db;
          svg {
            path {
              stroke: white;
            }
            circle {
              stroke: white;
            }
          }
        }
      }
      .arrow {
        display: flex;
        align-items: center;
        gap: 17px;
        svg {
          height: 24px;
          width: 24px;
          // color: #005287;
          cursor: pointer;
        }
        button {
          cursor: pointer;
          margin-right: 0px;
          svg {
            width: 30px;
            height: 30px;
          }
          &:hover {
            background-color: transparent !important;
          }
        }
      }
    }
  }
}
.custombtn {
  color: #216dde;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.24px;
  word-wrap: break-word;
  background-color: white;
  height: 34px;
  padding: 5px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  // border: 1px solid #005287;
  svg {
    margin-left: 10px;
  }
}
//viewfull
.viewfull {
  display: flex;
  flex-direction: row;
  // gap: 20px;
  background: white;
  border: 1px solid #d1d9e0;
  border-radius: 10px;
  margin-bottom: 10px;
  &_head {
    ul {
      display: flex;
      li {
        color: black;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.6px;
        word-wrap: break-word;
        margin-right: 20px;
        position: relative;
        cursor: default;
        text-transform: capitalize;
        &.active {
          color: #0098ee !important;
          cursor: pointer;
          a {
            color: #0098ee !important;
            cursor: pointer;
          }
        }
        &:last-child {
          &::after {
            content: unset;
          }
        }
        &::after {
          content: "";
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: #000;
          position: absolute;
          right: -11px;
          top: 0px;
          bottom: 0;
          margin: auto;
        }
      }
    }
  }
  &_left {
    width: calc(100% - 393px);
    border-right: 1px solid #d1d9e0;
    &_wrp {
      height: 681px;
      margin-bottom: 25px;
      position: relative;
    }
    &_top {
      display: flex;
      flex-direction: column;
      position: relative;
      // background-color: #fff;
      &_save {
        position: absolute;
        right: 10px;
        border-bottom: 0 !important;
      }
      >div {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: start;
        // gap: 10px;
        border-bottom: 1px solid #00000029;
      }
      .right_contracttitle {
        text-decoration: underline;
        font-size: 22px;
        font-weight: 700;
        color: #101010;
      }
      &_resize {
        color: #005287;
        span {
          font-size: 12px;
        }
        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 19px;
          letter-spacing: 0.6000000238418579px;
          text-align: left;
          // background: #e3f5fd;
          color: #505050;
          width: 60px;
          text-align: center;
          padding: 5px;
        }
      }
    }
    &_doc {
      height: 100%;
      max-height: 586px;
      padding: 20px 10px;
      overflow: auto;
      background-color: #fff;
      >div {
        padding: 0 20px;
        height: 100%;
        overflow: auto;
        line-height: normal;
      }
    }
    &_similar {
      background-color: #fff;
      margin-bottom: 20px;
      border-radius: 10px;
      h3 {
        padding: 15px 20px;
        font-size: 18px;
        font-weight: 700;
        line-height: 23px;
        text-align: left;
      }
      h4 {
        padding: 15px 20px;
        text-align: left;
        font-weight: 700;
        font-size: 20px;
        color: #101010;
        line-height: normal;
        border-top: 1px solid #d1d9e0;
        border-bottom: 1px solid #d1d9e0;
      }
      &_list {
        padding: 0;
        height: 100%;
        max-height: 365px;
        overflow: auto;
        ul {
          padding: 0 20px;
          max-height: 585px;
          overflow: auto;
          li {
            display: flex;
            flex-wrap: nowrap;
            border-bottom: 1px solid #b8b8b840;
            padding: 12px 0;
            cursor: pointer;
            &:last-child {
              margin-bottom: 0;
              border-bottom: none;
            }
            span {
              font-size: 15px;
              font-weight: 500;
              line-height: 19px;
              color: #216dde;
            }
          }
        }
      }
    }
  }
  &_right {
    width: 393px;
    padding: 20px;
    padding-top: 0px;
    &_meta {
      background-color: #fff;
      padding-bottom: 20px;
      border-bottom: 1px solid #d1d9e0;
      h4 {
        padding: 20px 0px;
        padding-bottom: 0px;
        text-align: left;
        font-weight: 700;
        font-size: 20px;
        color: #101010;
        line-height: normal;
        margin-bottom: 16px;
        // border-bottom: 1px solid #a8a8a8;
      }
      &_list {
        // padding: 15px 0;
        ul {
          // padding: 0 20px;
          max-height: 585px;
          overflow: auto;
          li {
            display: flex;
            flex-wrap: nowrap;
            // border-bottom: 1px solid #b8b8b840;
            // padding: 12px 0;
            padding-bottom: 8px;
            margin-bottom: 0;
            color: #216dde;
            cursor: default;
            &:last-child {
              border: none;
              &.source_link {
                span {
                  &:last-child {
                    a {
                      display: flex;
                      align-items: center;
                      gap: 2px;
                      color: #216dde;
                      cursor: pointer;
                    }
                  }
                }
              }
            }
            span {
              width: 50%;
              &:first-child {
                color: #303030;
                font-size: 16px;
                font-weight: 600;
                text-align: left;
              }
              &:last-child {
                font-size: 16px;
                font-weight: 400;
                line-height: 19px;
                color: #216dde;
                cursor: pointer;
                text-transform: capitalize;
              }
            }
          }
        }
      }
      &.agreement {
        ul {
          li {
            span {
              width: 100%;
              cursor: pointer;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}
.client_assignmodal {
  table {
    tbody {
      tr {
        border: none;
        td {
          border: none;
          &:nth-child(1) {
            padding: 5px 10px;
          }
        }
      }
    }
  }
}
.save_dropdwon {
  position: relative;
  cursor: pointer;
  .save_dropdwon_wrp {
    &.active {
      display: block;
      margin-top: 0;
      animation-name: down-animate;
      animation-duration: 0.5s;
    }
  }
  span {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &_wrp {
    position: absolute;
    display: none;
    right: 0;
    background: #ffff;
    top: 110%;
    box-shadow: 0px 2px 8px 0px #0000001f;
    border-radius: 10px;
    overflow: hidden;
    ul {
      width: 140px;
      li {
        padding: 0 10px;
        margin-bottom: 0;
        &:hover {
          background-color: #F8F8F8;
        }
        label {
          margin-left: 0;
          justify-content: space-between;
        }
        >span {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: 10px 0px;
          font-size: 0.875rem;
          align-items: center;
        }
      }
    }
  }
}
.customlist {
  display: flex;
  width: 100%;
  padding: 2px 5px;
  align-items: flex-start;
  cursor: pointer;
  span {
    padding: 0;
  }
  p {
    width: calc(100% - 29px);
    word-break: break-word;
    font-size: 14px;
    text-transform: capitalize;
  }
}
.main_div {
  input.MuiInputBase-input {
    background-color: white !important;
    color: #505050 !important;
    &::-webkit-input-placeholder {
      font-size: 12px;
      font-family: "Roboto" !important;
      color: #505050 !important;
    }
  }
  .MuiInputBase-sizeSmall {
    // padding-right: 39px !important;
    border-radius: 10px;
    // padding: 8px !important;
    padding: 10px !important;
    height: 40px;
    background-color: white;
    cursor: pointer !important;
    input {
      cursor: pointer;
      padding: 0px !important;
      &::placeholder {
        font-size: 14px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: 24px !important;
        letter-spacing: 0.7px !important;
        color: #606060 !important;
        opacity: 1;
      }
    }
  }
  .MuiAutocomplete-tag {
    display: none;
  }
  .MuiAutocomplete-clearIndicator {
    display: none;
  }
}
.textfield {
  width: 100%;
  .MuiInputBase-inputSizeSmall {
    font-size: 12px;
    background-color: white;
    width: 226px;
    color: #606060;
    &::placeholder {
      opacity: 1;
      font-size: 16px;
      color: #606060;
      font-weight: 400;
      font-family: "Satoshi";
    }
  }
}
.singleselect {
  .MuiAutocomplete-listbox {
    li {
      margin-bottom: 0;
    }
  }
}
.taginput {
  width: 151px;
  padding: 0 !important;
  position: relative !important;
  >div>div {
    padding: 0 !important;
    background-color: white;
    position: relative;
    height: 40px !important;
    min-width: 0;
    width: 151px;
    input {
      padding: 0;
      width: 151px;
      padding-left: 10px;
      opacity: 1 !important;
      color: #505050 !important;
      font-size: 14px !important;
      font-family: 'Satoshi' !important;
      &::placeholder {
        font-size: 14px !important;
        color: #505050 !important;
        opacity: 1 !important;
      }
    }
    fieldset {
      border-radius: 10px;
    }
  }
  fieldset~div {
    display: none !important;
  }
  .MuiOutlinedInput-root-22.MuiOutlinedInput-focused-23 .MuiOutlinedInput-notchedOutline-29,
  .jss22.jss23 .jss29 {
    border-color: #2196f3 !important;
    border-width: 2px;
  }
}
.search-result-found-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d1d9e0;
  padding: 10px 20px;
  div p {
    font-weight: 400;
    font-size: 16px;
  }
  div p span {
    font-weight: 600;
    font-size: 16px;
  }
  div span {
    font-weight: 600;
    font-size: 16px;
  }
  div button {
    font-weight: 500;
    color: #216dde;
    display: flex;
    gap: 5px;
    align-items: center;
  }
  #sort-by-section {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}
.search-result-tab {
  button {
    color: #505050 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    // padding: 10px;
    // padding-left: 4px;
    // padding-right: 4px;
    border-width: 2px;
    margin-right: 25px;
    padding: 0px;
  }
  .Mui-selected {
    color: #216dde !important;
    font-weight: 500 !important;
  }
}
.search-result-tab .MuiTabs-fixed>span {
  background-color: #216dde;
  height: 2.5px;
}
.custom-radio-button {
  border: 1px solid #d1d9e0;
  border-radius: 10px;
  display: flex;
  padding: 5px;
  div {
    display: inline-block;
  }
  input[type="radio"] {
    display: none;
    +label {
      color: #333;
      font-family: Arial, sans-serif;
      font-size: 14px;
      span {
        display: inline-block;
        cursor: pointer;
        border: 1px solid #ffffff;
        background-repeat: no-repeat;
        background-position: center;
        text-align: center;
        line-height: 28px;
        width: 160px;
        border-radius: 5px;
        color: #a0a0a0;
      }
    }
    &:checked+label span {
      border-color: #216dde;
      background-color: #e9f0fc;
      color: $primary;
    }
  }
}
.custom-filter-modal-container {
  .kd_modal {
    border-radius: 10px;
    border: 1px solid #d1d9e0;
    padding: 0px;
    .modal-footer-actions {
      padding: 10px;
      .cancel-btn {
        width: 130px;
        border-radius: 10px;
        border: 1px solid #216dde;
        padding: 10px;
        color: #216dde;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 12px;
        font-family: "Satoshi";
      }
      .select-btn {
        width: 130px;
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 12px;
        border-radius: 10px;
        border-bottom: 4px solid #184ea0;
        font-family: "Satoshi";
        background: #216dde;
        padding: 10px;
      }
    }
    .filter-search-container {
      padding: 10px 20px;
      padding-bottom: 0px;
      width: 100%;
      // > div {
      //   display: flex;
      //   padding: 8px 15px;
      //   gap: 10px;
      //   border: 1px solid #d1d9e0;
      //   border-radius: 10px;
      //   align-items: center;
      // }
    }
  }
  .modal-heading {
    color: #101010;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    /* 120% */
    letter-spacing: 1px;
    border-bottom: 1px solid #d1d9e0;
    padding: 10px 20px 15px;
  }
  .filter-list-item {
    // padding: 0px 10px;
    // padding-bottom: 5px;
    >div {
      // padding: 0px;
      // .MuiCheckbox-root {
      //   padding: 0px;
      //   svg {
      //   }
      // }
      .MuiListItemText-dense {
        span {
          color: #606060;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.8px;
          font-family: "Satoshi";
        }
      }
    }
  }
}
.more-filter-btn {
  border: 1px solid #d1d9e0;
  border-radius: 10px;
  border-color: #d1d9e0 !important;
  color: #606060 !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
}
.more-filter-list-container {
  .MuiPaper-root {
    border-radius: 10px !important;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);
    .MuiList-root {
      padding: 0px;
    }
    .more-filter-list {
      display: flex;
      flex-wrap: wrap;
      padding: 10px;
      justify-content: space-between;
      li {
        width: 50%;
        .MuiListItemIcon-root {
          width: 20px;
          min-width: 20px;
          .MuiCheckbox-root {
            padding: 0px;
          }
        }
        .MuiTypography-root {
          color: #303030;
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          /* 200% */
          font-family: "Satoshi";
        }
      }
    }
  }
}
.sort-by-section {
  .rs-dropdown-menu {
    right: 0 !important;
    left: auto !important;
    width: 200px !important;
    span {
      font-weight: 500;
      font-size: 14px;
    }
  }
}