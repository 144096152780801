.icon {
  width: 24px;
  height: 24px;
  position: relative;
  display: inline-block;
}
.icon-upi {
  height: 70px;
  width: 200px;
  position: relative;
  display: inline-block;
}
.icon-lg {
  height: 80px;
  width: 80px;
  position: relative;
  display: inline-block;
}
.icon-big {
  height: 105px;
  width: 105px;
  position: relative;
  display: inline-block;
}
.icon-xl {
  height: 130px;
  width: 130px;
  position: relative;
  display: inline-block;
}
.icon-xxl {
  height: 150px;
  width: 150px;
  position: relative;
  display: inline-block;
}

.icon-small {
  height: 50px;
  width: 50px;
  position: relative;
  display: inline-block;
}
.icon-64 {
  height: 64px;
  width: 64px;
  position: relative;
  display: inline-block;
}
.icon-40 {
  height: 40px;
  width: 40px;
  position: relative;
  display: inline-block;
}
.icon-pay {
  height: 55px;
  width: 55px;
  position: relative;
  display: inline-block;
}
.icon-xs {
  height: 30px;
  width: 30px;
  position: relative;
  display: inline-block;
}
.icon-lg,
.icon-xl,
.icon-small,
.icon-xs,
.icon-upi,
.icon-big,
.icon-pay,
.icon-xxl,
.icon-64 {
  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    left: 0;
    top: 0;
  }
}

.expend-less {
  &::before {
    background-image: url(../../assets/images/icons/expand_less.svg);
  }
}
