.pricing {
    .pricing-head {
        background-image: url("../../../assets/images/contactus.jpg");
        height: 136px;
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        &::before {
            content: "";
            background: rgba(9, 99, 157, 0.8);
            position: absolute;
            z-index: 1;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
        }
        .heading {
            position: relative;
            z-index: 1;
        }
    }
    .body {
        margin-top: 1.5rem;
        .body-head {
            text-align: center;
            h4 {
                font-weight: 700;
                font-size: 1.5rem;
                margin-bottom: 10px;
            }
        }
        .form-box {
            margin-top: 50px;
            padding: 50px;
            box-shadow: 2px 2px 5px #ababab;
            background: linear-gradient(45deg, #f2f2f2, #f8f8f8);
            margin-top: 3rem !important;
            margin-bottom: 3rem !important;
            width: 837px;
            margin: 0 auto;
            h5 {
                font-size: 1.5rem;
                font-weight: 700;
                margin-bottom: 1.5rem;
            }
            input {
                width: 100%;
                height: 40px;
                border-radius: 8px;
                outline: none;
                border: 2px solid #c4c4c4;
                padding: 0 30px;
                box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
                background: #fff;
            }
            .chakra-input__left-element {
                top: 0;
                bottom: 0;
                left: 10px;
            }
            .checkbox-agree {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 60px;
                a {
                    color: #0d6efd;
                    text-decoration: underline;
                }
            }
            .button {
                display: flex;
                justify-content: center;
                margin-top: 20px;
                .btn {
                    width: 429px;
                    border-radius: 4px;
                }
            }
        }
    }
}
.free-demo {
    display: flex;
    align-items: center;
    min-height: calc(100vh - 120px);
    .left {
        width: 50%;
        padding-right: 30px;
        h5 {
            font-size: 2.5rem;
            font-weight: bolder;
        }
        p {
            font-size: 17px;
            font-weight: 500;
            margin: 10px 0px;
            margin-bottom: 1.5rem;
        }
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            li {
                margin-top: 1rem !important;
                display: flex;
                align-items: flex-start;
                svg {
                    height: 15px;
                    width: 15px;
                    min-width: 15px;
                    margin-right: 0.5rem;
                    margin-top: 5px;
                }
            }
        }
    }
    .free-form {
        width: 50%;
        background: linear-gradient(45deg, #005287, #033e62);
        border-radius: 20px;
        padding: 3rem;
        color: #fff;
        h5 {
            font-size: 1.5rem;
            font-weight: 700;
            margin-bottom: 1.5rem;
        }
        input {
            display: block;
            width: 100%;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #212529;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border-radius: 0.25rem;
        }
        .btn {
            background-color: #fff !important;
            color: #005287 !important;
            font-weight: 600;
            transition: all 0.4s;
            padding: 10px 20px;
            display: inline-block;
            border: 2px solid #005287;
            width: 100%;
        }
        .checkbox-agree {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 60px;
            margin-top: 30px;
            margin-bottom: 20px;
            .form-check-label {
                margin-left: 7px;
            }
            a {
                color: #fff;
                text-decoration: underline;
            }
        }
    }
}
.tech-support {
    .contactus {
        justify-content: space-between;
    }
}
.install-now {
    padding-top: 4.5rem;
    padding-bottom: 1.5rem;
    p {
        margin-bottom: 15px;
    }
    ul {
        li {
            margin-bottom: 20px;
        }
    }
    a {
        color: #0d6efd;
        text-decoration: underline;
    }
}