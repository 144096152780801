.footer {
    background: #1C1E21;
    color: #ffffff;
    padding: 40px 5px;
    .container {
        max-width: 1720px;
    }
    &_item {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        flex-direction: column;
        margin-top: 16px;
        margin-bottom: 50px;
        &_left {
            width: 100%;
            padding-right: 15px;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            border-bottom: 1px solid #ffffff3b;
            padding-bottom: 20px;
            margin-bottom: 30px;
            @include bp(tab) {
                width: 100%;
                margin-bottom: 24px;
                flex-wrap: wrap;
            }
            .footer_logo {
                height: 84px;
            }
            h6 {
                font-weight: 600;
                text-transform: uppercase;
                font-size: 24px;
                padding: 8px 0;
                color: #FFFFFF;
                @include bp(mob) {
                    margin-top: 10px;
                    font-size: 18px;
                }
            }
            ul {
                margin-top: 15px;
                &.social_buttons {
                    display: flex;
                    li {
                        margin-right: 8px;
                        list-style: none;
                        a {
                            height: 36px;
                            width: 36px;
                            fill: #ffffff;
                            display: flex !important;
                            justify-content: center;
                            align-items: center;
                            font-size: 21px;
                            transition: background-color 250ms, border 250ms, transform 250ms;
                            display: flex;
                            svg {
                                fill: #ffffff;
                            }
                            &:hover {
                                transform: translateY(-4px);
                                color: #ffffff
                            }
                            &.facebook {
                                &:hover {
                                    svg {
                                        fill: #3c5a96;
                                    }
                                }
                            }
                            &.instagram {
                                &:hover {
                                    svg {
                                        fill: #704f9d;
                                    }
                                }
                            }
                            &.twitter {
                                &:hover {
                                    svg {
                                        fill: #000;
                                    }
                                }
                            }
                            &.linkedin {
                                &:hover {
                                    svg {
                                        fill: #0579b6;
                                    }
                                }
                            }
                            &.youtube {
                                &:hover {
                                    svg {
                                        fill: #ef561c;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &_right {
            display: flex;
            width: calc(100% - 33.33%);
            gap: 15px;
            margin: 0 auto;
            @include bp(tab) {
                width: 100%;
                flex-wrap: wrap;
                gap: 10px;
            }
            &_item {
                width: 25%;
                padding-right: 10px;
                @include bp(tab) {
                    width: 48%;
                }
                h6 {
                    font-weight: 600;
                    text-transform: uppercase;
                    font-size: 20px;
                    margin-bottom: 12px;
                }
                ul {
                    li {
                        list-style: none;
                        padding-bottom: 8px;
                        a {
                            text-decoration: none;
                            color: #FFFFFF;
                            text-transform: capitalize;
                            margin-bottom: 4px;
                            display: block;
                            transition: 0.4s all;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 22px;
                            &:hover {
                                color: #FFFFFF;
                                transform: translateX(5px);
                            }
                        }
                    }
                }
            }
        }
    }
}
.download_btn {
    border: 1px solid #ccc;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    gap: 10px;
    flex-wrap: nowrap;
    margin-top: 20px;
    border-radius: 4px;
}
.casetab {
    nav {
        display: flex;
        -webkit-box-pack: start;
        justify-content: flex-start;
        flex-direction: row;
        border-bottom-width: 2px;
        border-bottom-style: solid;
        border-color: inherit;
        button {
            outline-offset: 2px;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            padding: 8px 16px;
            margin-bottom: -2px;
            border-bottom-width: 2px;
            border-bottom-style: solid;
            &.active {
                border-bottom-width: 2px;
                border-color: #2b6cb0;
                color: #2b6cb0;
            }
        }
    }
    &_content {
        padding: 16px;
    }
}