@mixin bigDesktop {
  @media screen and (min-width: 768px) and (max-width: 992px) {
    @content;
  }
}
@mixin smallDesktop {
  @media screen and (min-width: 768px) and (max-width: 992px) {
    @content;
  }
}
@mixin tablet {
  @media screen and (min-width: 700px) and (max-width: 768px) {
    @content;
  }
}
@mixin phone {
  @media screen and (max-width: 700px) {
    @content;
  }
}
@mixin media($keys...) {
  @each $key in $keys {
    @if ($key ==bigDesktop) {
      @include bigDesktop {
        @content;
      }
    }
    @else if ($key ==smallDesktop) {
      @include smallDesktop {
        @content;
      }
    }
    @else if ($key ==tablet) {
      @include tablet {
        @content;
      }
    }
    @else if ($key ==phone) {
      @include phone {
        @content;
      }
    }
  }
}
.popupWithCheckModalBody {
  margin-top: 30px;
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  @include media(phone) {
    flex-direction: column;
    align-items: center;
  }
  img {
    width: 150px;
  }
  .right-side-container {
    width: 45%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    .login-create-title {
      h4 {
        color: rgb(0, 0, 0);
        font-weight: 700;
        font-size: 18px;
      }
      p {
        font-size: 14px;
        font-weight: 400;
      }
    }
    .create-account-with-section {
      align-self: center;
      width: 100%;
      p {
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        margin-bottom: 8px;
      }
      div {
        display: flex;
        justify-content: center;
        gap: 5px;
      }
      .already-registered-text {
        font-size: 14px;
        font-weight: 400;
        margin-top: 8px;
        line-height: 24px;
        word-spacing: 1px;
        span {
          color: #216dde;
          cursor: pointer;
        }
      }
    }
    // @include smallDesktop {
    //   width: 80%;
    //   margin-top: 20px;
    // }
    @include media(phone) {
      width: 80%;
      margin-top: 20px;
    }
  }
}
.premium-plan-title-container {
  // height: 200px;
  display: flex;
  flex-direction: column;
  @include media(smallDesktop) {
    width: 90% !important;
    height: 100%;
  }
  @include media(tablet, phone) {
    width: 100% !important;
    height: 100%;
  }
}
.premium-plan-title {
  color: rgb(236, 184, 40);
  font-weight: 700;
  font-size: 18px;
}
.premium-plan-ul {
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-flow: wrap;
  li {
    width: 50%;
    font-size: 14px;
    color: rgb(33, 109, 222);
    list-style: none;
    font-weight: 400;
    min-height: 32px;
    display: flex;
    align-items: center;
    gap: 5px;
    img {
      width: 15px;
    }
    svg {
      width: 17px;
      min-width: 17px;
      margin-right: 5px;
    }
  }
}
.modal-primary-button {
  background-color: rgb(33, 109, 222);
  border-bottom: 4px solid rgb(24, 78, 160);
  width: 100%;
  border-radius: 10px;
  color: white;
  font-size: 16px;
  align-self: center;
  padding: 8px 5px;
  font-weight: 700;
  min-width: 100px;
}
.red-title {
  font-size: 24px;
  text-align: center;
  color: rgb(255, 58, 58);
  font-weight: bold;
  border-bottom: 1px solid rgb(255, 58, 58);
  margin-bottom: 5px;
}
.social-media-login-button {
  border: 1px solid rgb(209, 217, 224);
  border-radius: 10px;
  width: 35%;
  text-align: center;
  padding: 7px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 20px;
  }
}
.close-modal-button {
  position: absolute;
  right: 15px;
  top: 12px;
  img {
    width: 18px;
  }
}
.modal-title {
  font-size: 30px;
  text-align: center;
  @include media(phone) {
    font-size: 23px;
  }
}
#modal-modal-description {
  font-size: 14px;
  margin-top: 8px;
  text-align: center;
}
.popup-check-list-container {
  width: 100%;
}
.signup-limit-reached-container {
  h4 {
    color: rgb(33, 109, 222);
    font-weight: 700;
    font-size: 18px;
  }
  p {
    font-size: 14px;
    font-weight: 400;
  }
}
.close_modal {
  position: absolute !important;
  right: 0;
  top: 0;
  z-index: 99999;
}