.library {
  .search-result-head {
    margin-top: 0;
    h4 {
      color: #000;
      font-family: "Satoshi";
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      /* 120% */
      letter-spacing: 1px;
    }
    .search-main {
      padding-bottom: 0;
    }
  }
  .search-result-body {
    height: calc(100vh - 150px);
    margin-bottom: 20px;
    border: 1px solid #e4e4e4;
  }
  .MuiCardContent-root {
    border-radius: 10px;
    border: 1px solid #d1d9e0;
    padding: 10px;
  }
  &_list {
    // border: 1px solid #d6d6d6;
    margin-bottom: 32px;
    &_header {
      padding: 0px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      //   border-bottom: 1px solid #d4d4d4;
      h6 {
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        text-align: left;
        font-family: "Satoshi";
        color: #303030;
      }
      span {
        color: #216dde;
        font-family: "Satoshi";
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        text-transform: capitalize;
        cursor: pointer;
        &.disable {
          color: #868686;
          pointer-events: none;
          cursor: default;
        }
      }
    }
    &_content {
      padding: 20px;
      ul {
        // -webkit-column-count: 3;
        // -moz-column-count: 3;
        // column-count: 3;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        li {
          cursor: default;
          div {
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 400;
            line-height: 30px;
            gap: 3px;
            // padding: 0 20px;
            span {
              font-size: 16px;
              font-weight: 400;
              line-height: 30px;
              //   text-decoration: underline;
              color: #216dde;
              margin-right: 2px;
              font-family: "Satoshi";
              cursor: pointer;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              text-transform: capitalize;
              word-break: break-all;
            }
            p {
              color: #707070;
              cursor: pointer;
            }
            svg {
              cursor: pointer;
              fill: #a0a0a0;
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
  .selected-filter {
    // margin-top: 12px;
    // display: flex;
    // justify-content: flex-start;
    // align-items: flex-start;
    // > ul {
    //     display: flex;
    //     flex-wrap: wrap;
    //     gap: 5px;
    //   li {
    //     border-radius: 4px;
    //     display: flex;
    //     align-items: flex-start;
    //     gap: 3px;
    //     margin-bottom: 0;
    //     ul {
    //         display: flex;
    //         flex-wrap: wrap;
    //         gap: 5px;
    //     }
    //     h6 {
    //       font-size: 14px;
    //       color: #303030;
    //       line-height: 28px;
    //     }
    //     .selected {
    //       // Product
    //       color: #005287;
    //       font-size: 12px;
    //       font-weight: 500;
    //       line-height: 12px;
    //       word-wrap: break-word;
    //       display: flex;
    //       align-items: center;
    //       justify-content: space-between;
    //       background-color: rgba(185, 229, 251, 0.4);
    //       height: 28px;
    //       padding: 8px 8px;
    //       border-radius: 4px;
    //       span {
    //         margin-right: 5px;
    //         display: block;
    //       }
    //       svg {
    //         cursor: pointer;
    //       }
    //     }
    //   }
    // }
    // button {
    //   margin-left: 10px;
    //   width: 80px;
    //   min-width: 80px;
    //   height: 27px;
    // }
  }
  .head-right {
    position: unset;
    margin-bottom: 10px;
    margin-right: 0;
    display: flex;
    justify-content: flex-end;
  }
}
.custom_client {
  .kd_modal {
    border-radius: 10px;
    padding: 10px 0px;
    height: auto;
    .custom-client-header {
      border-bottom: 1px solid #d1d9e0;
      padding: 0px 20px 15px 20px;
      padding-top: 0px;
      h4 {
        color: #101010;
        font-family: "Satoshi";
        font-size: 20px !important;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        /* 120% */
        letter-spacing: 1px;
      }
    }
    .custom-client-main-body {
      padding: 20px;
      margin: 0px;
      .MuiInputBase-root {
        border-radius: 10px;
        input {
          background-color: white;
          &::placeholder {
            color: #606060;
            font-family: "Satoshi";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.7px;
            opacity: 1;
          }
        }
      }
    }
    .custom-client-footer {
      padding: 0px 20px;
      .cancel-btn {
        border-radius: 10px;
        border: 1px solid #216dde;
        background: #fff;
        display: flex;
        width: 150px;
        height: 40px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: #216dde;
        font-family: "Satoshi";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 12px;
      }
      .add-btn {
        border-radius: 10px;
        border-bottom: 4px solid #184ea0;
        background: #216dde;
        display: flex;
        width: 150px;
        height: 40px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: #fff;
        font-family: "Satoshi";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 12px;
      }
    }
  }
  .MuiInputLabel-root {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.05em;
    text-align: left;
    margin-bottom: 10px;
    color: #303030;
  }
}
.save-library-list {
  li {
    // width: 24%;
  }
  .library_list_header {
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
    svg {
      cursor: pointer;
    }
  }
}
.sort-by-library {
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d1d9e0;
    border-radius: 10px;
    height: 40px;
    padding: 10px;
    gap: 7px;
    color: #606060;
    font-family: "Satoshi";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.7px;
  }
}
.library {
  .sort-by-section {
    left: 0;
    right: unset !important;
  }
}