.login {
    padding-top: 60px;
    height: 100vh;
    padding: 45px;
    font-family: $Satoshi;
    overflow: auto;
    max-width: 1920px;
    display: flex;
    flex-direction: column;
    margin: auto;
    padding-top: 10px !important;
    @include bp(mini) {
        padding: 30px;
    }
    @include bp(mob) {
        padding: 0;
        padding-top: 0 !important;
    }
    &_logo {
        padding-bottom: 10px;
    }
    &_header {
        @include bp(mob) {
            box-shadow: 0px 2px 26px 0px rgba(0, 0, 0, 0.2509803922);
            padding: 10px;
        }
    }
    &_wrp {
        padding: 40px 60px;
        box-shadow: 0px 2px 26px 0px #00000040;
        height: 100%;
        display: flex;
        flex-direction: column;
        // max-width: 1420px;
        margin: auto;
        overflow: auto;
        width: 100%;
        @include bp(mini) {
            padding: 30px;
        }
        @include bp(tab) {
            padding: 20px;
        }
        @include bp(mob) {
            box-shadow: unset;
        }
    }
    &_top {
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 30px;
        align-items: center;
        &_left {
            width: 50%;
            padding-right: 20px;
            text-align: left;
            @include bp(mob) {
                padding-right: 5px;
            }
        }
        &_right {
            width: 50%;
            padding-left: 20px;
            display: flex;
            justify-content: flex-end;
            font-size: 14px;
            font-weight: 400;
            span {
                color: $primary;
                cursor: pointer;
            }
            @include bp(mob) {
                padding-left: 5px;
                font-size: 0;
                span {
                    font-size: 13px;
                }
            }
        }
        h2 {
            font-size: 36px;
            width: -webkit-fill-available;
            text-align: center;
            @include bp(mini) {
                font-size: 30px;
            }
            @include bp(mob) {
                font-size: 26px;
            }
        }
    }
    &_bottom {
        display: flex;
        gap: 20px;
        flex: 1 auto;
        align-items: center;
        @include bp(mini) {
            flex-direction: row;
        }
        @include bp(tab) {
            flex-direction: column;
        }
        &_left {
            width: calc(50%);
            padding-right: 20px;
            &_wrp {
                max-width: 480px;
                margin: auto;
            }
            @include bp(tab) {
                width: calc(100%);
                padding-right: 0;
            }
        }
        &_right {
            width: calc(50%);
            @include bp(tab) {
                width: calc(100%);
            }
            h3 {
                font-size: 20px;
                @include bp(mini) {
                    font-size: 16px;
                }
            }
        }
    }
    .seprater {
        @include bp(mini) {
            margin: 25px 0;
            font-size: 14px;
        }
    }
    .submit_btn {
        width: 100%;
        button {
            border-radius: 10px;
            background: $primary;
            font-size: 16px;
            padding: 8px 5px;
            border-bottom: 4px solid $primaryborder;
            color: #fff;
            margin-bottom: 24px;
            font-weight: 700;
            @include bp(mini) {
                font-size: 15px;
                padding: 5px;
                margin-bottom: 16px;
            }
            &.disabled {
                opacity: 0.5;
            }
        }
        a {
            color: $primary;
            font-size: 16px;
            font-weight: 500;
            display: inline-flex;
            margin: 0 auto;
            @include bp(mini) {
                font-size: 14px;
            }
        }
    }
    &_social {
        display: flex;
        flex-direction: column;
        gap: 10px;
        button {
            border: 1px solid #404040;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 7px 10px;
            border-radius: 10px;
            gap: 10px;
            font-size: 16px;
            font-weight: 500;
            @include bp(mini) {
                font-size: 14px;
            }
        }
    }
}
.forgot {
    &_bottom {
        display: flex;
        justify-content: center;
        flex-direction: column;
        max-width: 500px;
        margin: auto;
        >img {
            max-width: 190px;
            margin: auto;
            margin-bottom: 20px;
        }
        &.thankyou {
            max-width: unset;
            img {
                max-width: 475px;
                width: 100%;
            }
            h3 {
                font-size: 39px;
                font-weight: 700;
                text-align: center;
                @include bp(mini) {
                    font-size: 29px;
                }
                @include bp(tab) {
                    font-size: 24px;
                }
                @include bp(mob) {
                    font-size: 20px;
                }
            }
            p {
                margin: auto;
                text-align: center;
                margin-bottom: 60px;
                font-size: 20px;
                font-weight: 500;
                @include bp(mini) {
                    font-size: 18px;
                    margin-bottom: 45px;
                }
                @include bp(tab) {
                    font-size: 16px;
                    margin-bottom: 35px;
                }
                @include bp(mob) {
                    font-size: 14px;
                    margin-bottom: 25px;
                }
            }
            .submit_btn {
                text-align: center;
                button {
                    max-width: 500px;
                    width: 100%;
                    a {
                        color: #fff;
                    }
                }
            }
        }
    }
    .submit_btn {
        button {
            margin-bottom: 0;
        }
        p {
            font-size: 12px;
            text-align: center;
        }
    }
    .verificationcode {
        max-width: 277px;
        width: 100%;
        border-radius: 10px;
        background-color: #E5F5E9;
        font-size: 14px;
        color: #00781A;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        padding: 10px;
        margin: auto;
        font-weight: 500;
    }
}
//register 
.register {
    &_option {
        max-width: 580px;
        margin: auto;
        &_wrp {
            margin-bottom: 50px;
            @include bp(mob) {
                gap: 15px;
            }
            &_item {
                cursor: pointer;
                @include bp(mob) {
                    width: 50%;
                }
                img {
                    width: 260px;
                    height: 260px;
                    padding: 10px;
                    border: 1px solid #000000;
                    border-radius: 16px;
                    margin-bottom: 26px;
                    transition: all 0.1s ease-in-out;
                    @include bp(mob) {
                        height: auto;
                    }
                }
                p {
                    font-weight: 700;
                    font-size: 24px;
                    color: #909090;
                    text-align: center;
                    @include bp(mob) {
                        font-size: 20px;
                    }
                }
                &.selected {
                    img {
                        border: 4px solid #216DDE;
                    }
                    p {
                        color: #216DDE;
                    }
                }
            }
        }
        .submit_btn {
            button {
                max-width: 500px;
                width: 100%;
                margin: auto;
            }
        }
    }
    .seprater {
        margin: 14px 0;
        font-size: 14px;
    }
    .submit_btn {
        margin-top: 40px;
        button {
            width: 100%;
        }
    }
}
.checkbox {
    display: flex;
    gap: 12px;
    align-items: center;
    .checkmark {
        position: relative;
        display: block;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        background: white;
        border-radius: 3px;
        outline: 1px solid #acacac;
        transition: all 0.2s ease;
        &::after {
            position: absolute;
            content: "";
            width: 14px;
            height: 9px;
            background-image: url('../../../assets/images/check.svg');
            transition: all 0.2s ease;
            z-index: 62;
            background-size: contain;
            display: block;
            background-repeat: no-repeat;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
        }
    }
    input:checked~.checkmark {
        background: #3f7fed;
        outline: 1px solid rgb(95, 126, 240);
    }
    h6 {
        max-width: calc(100% - 30px);
        cursor: pointer;
        @include bp(mob) {
            font-size: 12px;
        }
    }
}