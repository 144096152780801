/* roboto-100 - latin */
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src:
    url('../../../assets/fonts/roboto-v30-latin-100.woff2') format('woff2'),
    url('../../../assets/fonts/roboto-v30-latin-100.woff') format('woff'),
}
/* roboto-300 - latin */
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src:
    url('../../../assets/fonts/roboto-v30-latin-300.woff2') format('woff2'),
    url('../../../assets/fonts/roboto-v30-latin-300.woff') format('woff'),
}
/* roboto-regular - latin */
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src:
    url('../../../assets/fonts/roboto-v30-latin-regular.woff2') format('woff2'),
    url('../../../assets/fonts/roboto-v30-latin-regular.woff') format('woff'),
}
/* roboto-500 - latin */
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src:
    url('../../../assets/fonts/roboto-v30-latin-500.woff2') format('woff2'),
    url('../../../assets/fonts/roboto-v30-latin-500.woff') format('woff'),
}
/* roboto-700 - latin */
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src:
    url('../../../assets/fonts/roboto-v30-latin-700.woff2') format('woff2'),
    url('../../../assets/fonts/roboto-v30-latin-700.woff') format('woff'),
}
/* roboto-900 - latin */
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src:
    url('../../../assets/fonts/roboto-v30-latin-900.woff2') format('woff2'),
    url('../../../assets/fonts/roboto-v30-latin-900.woff') format('woff'),
}


///////// satoshi //////// 
@font-face {
  font-family: 'Satoshi';
  src: url('../../../assets/fonts/Satoshi/Satoshi-Light.woff2') format('woff2'),
    url('../../../assets/fonts/Satoshi/Satoshi-Light.woff') format('woff');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Satoshi';
  src: url('../../../assets/fonts/Satoshi/Satoshi-Regular.woff2') format('woff2'),
    url('../../../assets/fonts/Satoshi/Satoshi-Regular.woff') format('woff');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Satoshi';
  src: url('../../../assets/fonts/Satoshi/Satoshi-Medium.woff2') format('woff2'),
    url('../../../assets/fonts/Satoshi/Satoshi-Medium.woff') format('woff');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Satoshi';
  src: url('../../../assets/fonts/Satoshi/Satoshi-Bold.woff2') format('woff2'),
    url('../../../assets/fonts/Satoshi/Satoshi-Bold.woff') format('woff');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Satoshi';
  src: url('../../../assets/fonts/Satoshi/Satoshi-Black.woff2') format('woff2'),
    url('../../../assets/fonts/Satoshi/Satoshi-Black.woff') format('woff');
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}