.container {
  &.description {
    max-width: 1820px;
    width: 100%;
  }
}
.sub_header {
  background-color: #E9F0FC;
  padding: 10px;
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 100px;
    li {
      margin-bottom: 0;
      a {
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        color: #000000;
      }
    }
  }
}
.home {
  &_banner {
    padding: 60px 20px;
    background-size: cover;
    display: flex;
    align-content: center;
    background-color: #fff;
    .container {
      max-width: 1560px;
      margin: auto;
      text-align: center;
    }
    &_left {
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      width: 100%;
      &_wrp {
        max-width: 792px;
        margin: auto;
        width: 100%;
        text-align: center;
        h1 {
          color: #283732;
          font-size: 46px;
          font-weight: 900;
          margin: auto;
          margin-bottom: 20px;
        }
        &_tab {
          font-family: $Satoshi;
          &.active {
            color: #216DDE !important;
          }
        }
      }
    }
    &_wrp {
      h2 {
        font-size: 32px;
        color: #fff;
        opacity: 0.7;
        font-weight: 600;
        line-height: normal;
      }
    }
    &_search {
      max-width: 1080px;
      width: calc(100% - 30px);
      margin: 0 auto;
      &_radio {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        gap: 20px;
        margin-top: 12px;
        .custom-radio-button {
          border: 1px solid #D1D9E0;
          border-radius: 10px;
          display: flex;
          padding: 5px;
          div {
            display: inline-block;
          }
          input[type="radio"] {
            display: none;
            +label {
              color: #333;
              font-family: Arial, sans-serif;
              font-size: 14px;
              span {
                display: inline-block;
                cursor: pointer;
                border: 1px solid #ffffff;
                background-repeat: no-repeat;
                background-position: center;
                text-align: center;
                line-height: 28px;
                width: 160px;
                border-radius: 5px;
                color: #A0A0A0;
              }
            }
            &:checked+label span {
              border-color: #216DDE;
              background-color: #E9F0FC;
              color: $primary;
            }
          }
        }
        .more-filter {
          width: 109px;
          padding-top: 10px;
          display: flex;
          align-items: center;
          .form-group {
            display: block;
            margin-bottom: 15px;
          }
          .form-group input {
            padding: 0;
            height: initial;
            width: initial;
            margin-bottom: 0;
            display: none;
            cursor: pointer;
          }
          .form-group label {
            position: relative;
            cursor: pointer;
            color: $primary;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            font-size: 14px;
          }
          .form-group label:before {
            content: '';
            -webkit-appearance: none;
            background-color: transparent;
            border: 2px solid $primary;
            padding: 10px;
            display: inline-block;
            position: relative;
            vertical-align: middle;
            cursor: pointer;
            margin-right: 10px;
            border-radius: 6px;
          }
          .form-group input:checked+label:before {
            background-color: $primary;
          }
          .form-group input:checked+label:after {
            content: '';
            display: block;
            position: absolute;
            top: 2px;
            left: 9px;
            width: 6px;
            height: 14px;
            border: solid #fff;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
          }
        }
      }
      &_morefilter {
        max-width: 1080px;
        width: calc(100% - 30px);
        margin: 0 auto;
        margin-top: 10px;
        .more-filters {
          flex-wrap: wrap;
          >div {
            @include bp(mob) {
              width: 100%;
              flex-basis: 100%;
              max-width: 100%;
            }
            .async-select__control .async-select__placeholder {
              margin-left: 20px;
              @include bp(tab) {
                margin-left: 10px;
                font-size: 18px;
              }
            }
          }
        }
      }
      &_demo {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
      }
      &_footer {
        margin-top: 130px;
        &_left {
          h4 {
            font-size: 20px;
            font-weight: 600;
            line-height: 30px;
          }
          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }
    }
  }
  
  &_addin {
    max-width: 1514px !important;
    padding: 49px 20px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    &_left {
      width: 40%;
      display: flex;
      align-items: center;
      // justify-content: center;
    }
    &_right {
      width: 60%;
      h2 {
        font-size: 40px;
        font-weight: 700;
        max-width: 624px;
        margin-bottom: 24px;
      }
      p {
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 27px;
      }
      a {
        background-color: #F3FAFF;
        width: 273px;
        height: 60px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        span {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          font-size: 18px;
          font-weight: 500;
          color: $primary;
        }
        img {
          width: 49px;
        }
        svg {
          color: $primary;
        }
      }
    }
  }
  &_draft {
    max-width: 1514px !important;
    padding: 49px 20px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    &_right {
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    &_left {
      width: 60%;
      h2 {
        font-size: 40px;
        font-weight: 700;
        margin-bottom: 24px;
      }
      p {
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 27px;
      }
      &_content {
        margin-top: 40px;
        max-width: 667px;
        label {
          color: #ECB828;
          font-size: 20px;
          font-weight: 700;
          margin-bottom: 24px;
          display: block;
        }
        ul {
          column-count: 2;
          li {
            color: $primary;
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 18px;
            svg {
              width: 24px;
              height: 24px;
            }
            span {
              width: calc(100% - 28px);
              font-size: 20px;
              font-weight: 500;
            }
          }
        }
        &_btn {
          display: flex;
          gap: 15px;
          margin-top: 45px;
          button {
            width: 50%;
            height: 60px;
          }
        }
      }
    }
  }
  &_popular {
    max-width: 1514px !important;
    padding: 49px 20px !important;
    margin: auto;
    &_wrp {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      justify-content: center;
      align-items: center;
      gap: 32px;
      &_item {
        width: 100%;
        border: 2px solid $primary;
        background-color: #FDFEFF;
        border-radius: 10px;
        h4 {
          border-radius: 10px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          font-size: 24px;
          font-weight: 500;
          line-height: 36px;
          display: flex;
          align-items: center;
          gap: 10px;
          padding: 30px 60px;
          background-color: $primary;
          color: #fff;
          svg {
            path {
              fill: #fff;
            }
          }
        }
        ul {
          display: flex;
          flex-wrap: wrap;
          padding: 22px 60px;
          li {
            width: 100%;
            margin-bottom: 24px;
            a {
              display: inline-block;
              font-size: 20px;
              font-weight: 400;
              line-height: 30px;
              transition: all 0.3s ease-in-out;
              &:hover {
                text-decoration: underline;
                font-weight: 500;
                padding-left: 10px;
              }
            }
          }
        }
        &:nth-child(2) {
          border-color: #F29427;
          background-color: #FFFCF9;
          h4 {
            background-color: #F29427;
          }
        }
        &:nth-child(3) {
          border-color: #AA3EC6;
          background-color: #FEF9FF;
          h4 {
            background-color: #AA3EC6;
          }
        }
      }
    }
  }
  &_features {
    max-width: 1050px !important;
    padding: 49px 20px !important;
    padding-bottom: 100px !important;
    &_wrp {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: center;
      align-items: center;
      gap: 50px;
      &_item {
        width: 100%;
        text-align: left;
        padding: 40px;
        border-radius: 10px;
        height: 100%;
        img {
          margin-bottom: 8px;
          max-width: 60px;
        }
        h3 {
          font-size: 24px;
          font-weight: 700;
          color: #fff;
          margin-bottom: 8px;
        }
        p {
          font-size: 20px;
          font-weight: 500;
          color: #fff;
        }
        &:first-child {
          background: linear-gradient(107.05deg, rgba(87, 187, 132, 0.9) 6.82%, rgba(27, 132, 74, 0.9) 97.11%);
        }
        &:nth-child(2) {
          background: linear-gradient(106.47deg, rgba(255, 123, 123, 0.9) 2.47%, rgba(175, 33, 33, 0.9) 93.33%);
        }
        &:nth-child(3) {
          background: linear-gradient(106.43deg, rgba(116, 119, 206, 0.9) 2.15%, rgba(31, 36, 166, 0.9) 93.61%);
        }
        &:nth-child(4) {
          background: linear-gradient(109.23deg, rgba(255, 149, 72, 0.9) 12.24%, rgba(201, 102, 30, 0.9) 93.76%);
        }
      }
    }
  }
  &_benefit {
    display: flex;
    max-width: 1514px !important;
    border-radius: 10px;
    .section_title {
      width: 100%;
      max-width: calc(100% - 70%);
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $primary;
      border-radius: 10px;
      color: #fff;
      padding: 0 25px;
      margin-bottom: 0;
    }
    &_wrp {
      display: grid;
      grid-template-columns: 1fr 1fr;
      max-width: 70%;
      width: 100%;
      gap: 20px;
      padding-left: 25px;
      &_item {
        width: 100%;
        max-width: 544px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-right: 50px;
        padding: 20px;
        &_img {
          display: flex;
          align-items: center;
          gap: 8px;
          margin-bottom: 16px;
          &_wrp {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 48px;
            }
          }
          h3 {
            font-size: 24px;
            font-weight: 700;
            color: #000;
          }
        }
        p {
          font-size: 20px;
          font-weight: 400;
          color: #707070;
        }
        &:nth-child(1) {
          .home_benefit_wrp_item_img {
            &_wrp {
              background-color: #F7E9FD;
            }
          }
        }
        &:nth-child(2) {
          .home_benefit_wrp_item_img {
            &_wrp {
              background-color: #D9FCDD;
            }
          }
        }
        &:nth-child(3) {
          .home_benefit_wrp_item_img {
            &_wrp {
              background-color: #FCF3DB;
            }
          }
        }
        &:nth-child(4) {
          .home_benefit_wrp_item_img {
            &_wrp {
              background-color: #E2F2FA;
            }
          }
        }
      }
    }
  }
  &_libraries {
    display: flex;
    max-width: 1514px !important;
    width: 100%;
    padding: 100px 20px !important;
    &_left {
      width: 40%;
    }
    &_right {
      width: 70%;
      justify-content: flex-start;
      .section_title {
        margin-bottom: 50px;
        h2 {
          text-align: left;
        }
      }
      &_content {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 2vw;
        &_item {
          width: 100%;
          display: flex;
          align-items: center;
          align-content: center;
          justify-content: center;
          flex-direction: column;
          background-color: #EFE9FC;
          padding: 5vw 4vw;
          border-radius: 10px;
          @media screen and (min-width:'1920px') {
            padding: unset;
            width: 250px;
            height: 250px;
          }
          img {
            width: 50px;
            margin-bottom: 12px;
          }
          h4 {
            font-size: 20px;
            font-weight: 700;
          }
          &:nth-child(1) {
            background-color: #EFE9FC;
            h4 {
              color: #6B32E4;
            }
          }
          &:nth-child(2) {
            background-color: #FEE8E8;
            h4 {
              color: #E34545;
            }
          }
          &:nth-child(3) {
            background-color: #E2F1FE;
            h4 {
              color: #2C95F1;
            }
          }
        }
      }
    }
    .home_features_wrp_item_content {
      h3 {
        font-size: 20px;
        font-weight: 500;
        line-height: 23px;
        margin-bottom: 15px;
      }
    }
  }
  &_ourtools {
    background-color: #E9F0FC;
    .home-container {
      max-width: 1514px !important;
      padding: 60px 50px;
    }
    &_item {
      padding: 0 15px;
      &_wrp {
        padding: 40px;
        background-color: #fff;
        border-radius: 10px;
        margin-bottom: 5px;
        border: 1px solid #CCCCCC
      }
      &_top {
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        &_image {
          width: 50px;
          height: 50px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        &_title {
          font-size: 24px;
          font-weight: 600;
          line-height: 28px;
        }
      }
      &_content {
        p {
          font-size: 20px;
          font-weight: 400;
          margin-bottom: 10px;
          min-height: 60px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        a {
          color: $primary;
          display: flex;
          align-items: center;
          gap: 5px;
          font-weight: 500;
          font-size: 20px;
          svg {
            width: 10px;
          }
        }
      }
    }
  }
  &_blog {
    max-width: 1514px !important;
    padding: 60px 20px;
    &_wrp {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 30px;
    }
    &_item {
      width: 100%;
      &_img {
        margin-bottom: 10px;
        border-radius: 10px;
        img {
          height: 331px;
          width: 100%;
          object-fit: cover;
          border-radius: 10px;
        }
      }
      &_content {
        h4 {
          font-size: 24px;
          font-weight: 700;
          line-height: 36px;
          margin-bottom: 10px;
        }
        p {
          font-size: 18px;
          font-weight: 400;
          line-height: 27px;
        }
      }
    }
  }
}
.section_title {
  text-align: center;
  margin-bottom: 32px;
  h2 {
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
  }
}
// More Filter
.more-filter {
  color: #00a0dc;
  font-size: 16px;
  font-weight: 500;
  word-wrap: break-word;
  cursor: pointer;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0;
  margin-left: 15px;
  svg {
    height: 24px;
    width: 24px;
    margin-left: 5px;
  }
}
.MuiTabs-root {
  .MuiTab-root {
    font-size: 18px;
    font-weight: 700;
    border: none;
    color: #707070;
    &.active {
      // Contract Type
      color: #00A0DC;
    }
  }
}
.search-box {
  position: relative;
  .css-py1ukw-MuiInputBase-root-MuiOutlinedInput-root fieldset {
    border: none !important;
  }
  input {
    @include bp(tab) {
      padding-left: 22px;
      font-size: 18px;
    }
  }
  .search_icon {
    height: 100%;
    z-index: 1;
    cursor: pointer;
    width: 25px;
    justify-content: center;
    margin-left: 10px;
    button {
      margin-right: 6px;
    }
    svg {
      stroke: #fff;
    }
    .notranslate {
      display: none;
    }
  }
  .search-result {
    position: absolute;
    top: 97%;
    left: 0;
    width: 100%;
    background: #fff;
    border-top: none;
    border-radius: 6px;
    z-index: 999;
    max-height: 300px;
    overflow-y: auto;
    box-shadow: 0 -3px 15px 0px #0000002E;
    &.hide-class {
      display: none;
    }
    .search-item {
      padding: 10px;
      color: #303030;
      font-size: 20px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 1.2px;
      word-wrap: break-word;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-bottom: 0;
      text-align: left;
      &:hover {
        background: #f5f5f5;
      }
      svg {
        margin-right: 8px;
        width: 20px;
      }
      p {
        width: calc(100% - 30px);
        color: #303030;
        font-size: 18px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.2px;
        word-wrap: break-word;
        text-transform: capitalize;
        color: #606060;
        strong {
          color: #216DDE;
        }
      }
    }
  }
}
.home_banner_search {
  .search_icon {
    width: 30px;
  }
}