.product_main_title {
    justify-content: center;
    // background: rgba(9, 99, 157, 0.8);
    color: #fff;
    background-size: cover;
    background-position: top;
    .container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        h2 {
            font-weight: 700;
            width: 100%;
            display: block;
            text-align: center;
            @include bp(tab) {
                font-size: 30px;
            }
        }
        p {
            font-size: 18px;
            text-align: center;
            font-weight: 500;
            font-family: $Roboto !important;
            line-height: 27px;
            * {
                color: #fff !important;
            }
        }
    }
}
.termsTabs {
    border: none;
    @include bp(tab) {
        &[aria-orientation] {
            orientation: "vertical";
        }
    }
}
.termsTabs {
    display: flex !important;
    flex-direction: row;
    gap: 15px;
    @include bp(tab) {
        flex-direction: column;
    }
    .termsTablist {
        width: 35%;
        border: none !important;
        display: flex;
        flex-direction: column;
        @include bp(tab) {
            width: 100%;
        }
        &_item {
            background: #f1f1f1 !important;
            color: #666 !important;
            border-radius: 5px;
            font-weight: 400;
            outline: none !important;
            border: unset !important;
            padding: 8px 16px;
            &[aria-selected=true] {
                background-color: #005287 !important;
                color: #fff !important;
                outline: none !important;
            }
        }
    }
    .termsTabPanel {
        width: 65%;
        @include bp(tab) {
            width: 100%;
        }
    }
    .termsTabContent {
        border-radius: 4px;
        box-shadow: 0 5px 5px 0 rgb(154 160 185 / 5%), 0 5px 30px 0 rgb(166 173 201 / 22%);
    }
}