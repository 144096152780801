body {
  button.primary {
    background: #005287;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.8px;
    height: 44px;
  }
  button.secondary, a.secondary {
    background: transparent;
    color: #005287 !important;
    border: 2px solid #005287 !important;
    text-decoration: none;
  }
}
