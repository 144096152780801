body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: normal;
    overflow: unset !important;
    overflow-x: hidden !important;
    min-width: unset !important;
    font-size: 16px;
    padding: 0;
    color: #212529 !important;
    background-color: #f5f5f5;
    letter-spacing: normal !important;
    font-family: $Satoshi ;
}
body * {
    font-family: $Satoshi ;
}
h1, h2 {
    line-height: 1.2;
    font-weight: bold;
}
h1, .h1 {
    font-size: 42px;
    @include bp(mob) {
        font-size: 36px;
    }
}
h2, .h2 {
    font-size: 36px;
    @include bp(tab) {
        font-size: 30px;
    }
    @include bp(mob) {
        font-size: 18px;
    }
}
h3 {
    font-size: revert;
    font-weight: revert;
}
// h3, .h3 {
//     font-size: 30px;
//     @include bp(tab) {
//         font-size: 26px;
//     }
//     @include bp(mob) {
//         font-size: 18px;
//     }
// }
// h4, .h4 {
//     font-size: 26px;
//     @include bp(tab) {
//         font-size: 18px;
//     }
// }
// h5, .h5 {
//     font-size: 18px;
//     @include bp(tab) {
//         font-size: 16px;
//     }
// }
// h6, .h6 {
//     font-size: 16px;
// }
.container {
    margin: auto;
    max-width: 1330px;
    width: 100%;
    padding: 0 15px;
    position: relative;
}
img {
    width: auto;
    height: auto;
    margin: auto;
}
.bg_pink {
    background: $lightpink;
    width: 100%;
}
.bg_blue {
    background: $theme;
    width: 100%;
}
.bg_body {
    background: $deemBlack;
}
.bg_white {
    background: $white !important;
    width: 100%;
}
.no_bg {
    background: none;
    width: 100%;
}
.grey-btn {
    @include grey-btn;
}
.text-input {
    @include log-input;
    &.error {
        border-color: red;
    }
}
.bg_light {
    background: $deemBlack;
}
.d_flex {
    @include displayflex;
}
input[type="radio"],
input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    position: absolute;
    left: -9999px;
}
button {
    outline: none;
    cursor: pointer;
}
.g_notfount_product {
    text-align: center;
    margin-bottom: 10px;
}
.disabledBtn {
    cursor: auto !important;
    -webkit-user-select: none;
    user-select: none !important;
}
.disabledBtnopacity {
    cursor: auto !important;
    -webkit-user-select: none;
    user-select: none !important;
    opacity: 0.5 !important;
}
.enableBtn {
    pointer-events: all;
    -webkit-user-select: auto;
    user-select: auto;
}
.nodataFound {
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin: 0 !important;
    padding: 10px;
    text-transform: uppercase;
    width: 100%;
    border-radius: 3px;
    font-family: $Roboto;
    &.normal {
        padding: 30px 10px;
        text-transform: unset;
    }
    &.ttunset {
        text-transform: unset;
    }
    .anchor {
        cursor: pointer;
        transition: all 0.5s ease;
        text-decoration: underline;
        &:hover {
            color: $radishpink;
        }
    }
    .link {
        background: transparent;
        padding: 0;
        border: none;
    }
}
.center {
    text-align: center;
    justify-content: center;
}
.disable_btn {
    pointer-events: none;
    opacity: 0.7;
}
.clear {
    clear: both;
}
.capitalize-msg {
    text-transform: capitalize;
}
input::-moz-focus-outer {
    border: 0;
}
.cursor {
    cursor: pointer;
}
label {
    font-weight: normal;
}
.line-through {
    text-decoration: line-through;
    display: inline !important;
}
.loader {
    position: absolute;
    z-index: 99999;
    height: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
        display: block;
        width: 40px;
        height: 40px;
        border: 3px solid;
        border-radius: 50%;
        border-color: transparent #000 #000;
        -webkit-animation: cssload-spin 690ms infinite linear;
        animation: cssload-spin 690ms infinite linear;
    }
}
@keyframes cssload-spin {
    100% {
        transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
.btn {
    font-weight: 600;
    transition: all 0.4s;
    padding: 10px 18px;
    display: inline-block;
    border: 2px solid $theme;
    border-radius: 5px;
    font-size: 14px;
}
.btn-outline {
    background-color: #fff !important;
    color: $theme !important;
    &:hover {
        box-shadow: 2px 2px 5px rgb(0 0 0 / 10%);
    }
}
.btn-blue {
    background-color: $theme !important;
    color: #fff !important;
    &:hover {
        box-shadow: 2px 2px 5px #00000091;
        transform: scale(1.02);
    }
}
.section_title {
    font-size: 1.8rem;
    line-height: 1.2;
    font-weight: 700;
}
.subheading {
    font-size: 17px;
    font-weight: 500;
    margin: 10px 0px;
}
.bg-theme {
    background-color: $bgtheme;
}
.overflow_hidden {
    overflow: hidden !important;
}
.kd_modal {
    position: relative;
    .close_modal {
        position: absolute;
        right: 0;
        top: 0;
    }
}
// slick customizations
.slick-prev, .slick-next {
    &:hover {
        opacity: 1;
    }
}
.slick-prev {
    background-image: url('../../../../public/images/circle-prev.svg') !important;
    background-size: cover !important;
    left: -40px !important;
    width: 40px !important;
    height: 40px !important;
    &::before {
        content: unset !important;
    }
    @include bp(1200px) {
        width: 28px !important;
        height: 28px !important;
        left: -28px !important;
    }
}
.slick-next {
    background-image: url('../../../../public/images/circle-next.svg') !important;
    background-size: cover !important;
    right: -40px !important;
    width: 40px !important;
    height: 40px !important;
    &::before {
        content: unset !important;
    }
    @include bp(1200px) {
        width: 28px !important;
        height: 28px !important;
        right: -28px !important;
    }
}
.container.ql-snow {
    width: 100%;
    background-color: #fff;
    >div {
        margin: auto;
        @media screen and (min-width: 1400px) {
            max-width: 1320px;
        }
        @media screen and ((min-width: 1200px)) {
            max-width: 1320px;
        }
    }
}
.staticdata {
    width: 100%;
    >div {
        margin: auto;
        @media screen and (min-width: 1400px) {
            max-width: 1320px;
        }
        @media screen and ((min-width: 1200px)) {
            max-width: 1320px;
        }
    }
}
button {
    &.bg_btn {
        border-radius: 10px;
        background: $primary;
        font-size: 16px;
        padding: 8px 5px;
        border-bottom: 4px solid $primaryborder;
        color: #fff;
        font-weight: 700;
        width: 100px;
        a {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            text-decoration: unset;
        }
    }
    &.normal_btn {
        border-radius: 10px;
        font-size: 16px;
        padding: 8px 5px;
        border: 1px solid $primaryborder;
        color: #216DDE;
        font-weight: 700;
        width: 100px;
    }
}
.customcheck {
    label {
        position: relative;
        cursor: pointer;
        color: #606060;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 14px;
        display: flex;
        align-items: flex-start;
        line-height: normal;
        width: 100%;
        padding: 10px 0;
        justify-content: flex-start;
    }
    label:before {
        content: '';
        -webkit-appearance: none;
        background-color: transparent;
        border: 1px solid #606060;
        padding: 10px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 10px;
        border-radius: 6px;
    }
    input:checked+label:before {
        background-color: $primary;
        border-color: $primary;
    }
    input:checked+label:after {
        content: '';
        display: block;
        position: absolute;
        top: 9px;
        left: 0;
        width: 22px;
        height: 22px;
        background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEyIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMS4yMDY2IDAuOTk4NTA3QzExLjM5NzYgMS4xOTc1MSAxMS4zOTI2IDEuNTE0NTEgMTEuMTkyNiAxLjcwNTUxTDYuNDUzNiA2LjI3MzUxQzUuOTg4NiA2LjczOTUxIDUuMzYwNiA2Ljk5OTUxIDQuNjkyNiA2Ljk5OTUxQzQuMDI0NiA2Ljk5OTUxIDMuMzk2NiA2LjczOTUxIDIuOTI0NiA2LjI2NzUxTDAuNjU5NTk3IDQuMTYzNTFDMC40NTc1OTcgMy45NzU1MSAwLjQ0NTU5NyAzLjY1OTUxIDAuNjMzNTk3IDMuNDU2NTFDMC44MjI1OTcgMy4yNTM1MSAxLjEzODYgMy4yNDQ1MSAxLjM0MDYgMy40MzA1MUwzLjYxODYgNS41NDc1MUM0LjIxMTYgNi4xMzk1MSA1LjE4NjYgNi4xMjY1MSA1Ljc1MjYgNS41NjA1MUwxMC40OTg2IDAuOTg2NTA4QzEwLjY5NjYgMC43OTU1MDggMTEuMDEzNiAwLjgwMDUwOCAxMS4yMDU2IDAuOTk5NTA4TDExLjIwNjYgMC45OTg1MDdaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K');
        background-repeat: no-repeat;
        background-size: 13px;
        margin: auto;
        background-position: center;
    }
    &.rightside {
        label {
            flex-direction: row-reverse;
            &::before {
                margin-right: 0;
                margin-left: 10px;
            }
        }
        input:checked+label:after {
            right: 0;
            left: unset;
        }
    }
}
//btn css
.blue_btn {
    border-radius: 10px;
    background: $primary;
    font-size: 16px;
    padding: 8px 5px;
    border: none;
    border-bottom: 4px solid $primaryborder;
    color: #fff;
    font-weight: 700;
    min-width: 100px;
    &:disabled {
        opacity: 0.7;
        background: $grey;
        border-bottom: 4px solid $grey;
    }
}
.outline_btn {
    border-radius: 10px;
    font-size: 16px;
    padding: 8px 5px;
    background-color: transparent;
    border: 1px solid #184EA0;
    color: #216DDE;
    font-weight: 700;
    min-width: 100px;
}