.navbar {
  position: sticky;
  top: 0;
  // box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
  z-index: 999;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 0 20px;
  &_wrp {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    width: 100%;
    .logo {
      width: 100%;
      max-width: 190px;
      padding: 15px 0;
      img {
        display: block;
        object-fit: contain;
      }
    }
    &_left {
      width: 100%;
    }
    .aftlog {
      display: block;
      position: relative;
      margin-bottom: 0;
      height: 100%;
      display: flex;
      align-items: center;
      height: 100%;
      span {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      a {
        font-size: 14px;
      }
      .dropdown-menu {
        position: absolute;
        z-index: 1000;
        display: none;
        margin: 0;
        font-size: 1rem;
        color: #212529;
        text-align: left;
        list-style: none;
        background-color: #fff;
        right: 0;
        left: 0;
        width: 100%;
        li {
          margin-bottom: 0;
          padding: 10px;
          &:hover {
            background-color: #03639d;
            a {
              color: #fff !important;
            }
          }
          a {
            text-decoration: none;
            color: #212529;
          }
        }
      }
      &:hover {
        .dropdown-menu {
          top: 40px;
          margin: auto;
          display: block;
          margin-top: 0;
          box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1411764706);
          -webkit-animation-name: down-animate;
          animation-name: down-animate;
          -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
        }
      }
    }
    &_right {
      gap: 8px;
      .add-custom-client-btn {
        border-radius: 10px;
        border-bottom: 4px solid #184ea0;
        background: #216dde;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-family: "Satoshi";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 12px;
        width: 250px;
        padding: 10px 10px 5px 10px;
      }
      .remove-custom-client-btn {
        border-radius: 10px;
        border: 1px solid #216dde;
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #216dde;
        font-family: "Satoshi";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 12px;
        width: 250px;
        padding: 7px 10px 7px 10px;
      }
      &_search {
        position: relative;
        margin: auto;
        margin-left: 0;
        // width: 258px;
        @include bp(1920px) {
          width: 258px;
        }
        @include bp(1420px) {
          width: 100%;
        }
        input {
          font-size: 14px;
          background-color: #f2f8fb;
          border-color: #00528780;
        }
        button {
          position: absolute;
          top: 0;
          left: 6px;
          bottom: 0;
          margin: auto;
          display: flex;
          align-items: center;
          z-index: 1;
        }
      }
      &_icon {
        display: flex;
        align-items: center;
        gap: 26px;
        @include bp(1420px) {
          gap: 20px;
        }
      }
    }
  }
  &_nav {
    display: flex;
    height: 100%;
    >li {
      height: 100%;
      margin-right: 24px;
      align-items: center;
      display: flex;
      font-size: 16px;
      @include bp(1420px) {
        margin-right: 15px;
      }
      svg {
        transition: all 0.3s;
      }
      &:hover {
        a {
          cursor: pointer;
          svg {
            transform: rotate(-180deg);
          }
        }
        .dropdown-menu {
          top: 69px;
          display: block;
          margin-top: 0;
          box-shadow: 0px 2px 6px 0px #0000001f;
          animation-name: down-animate;
          animation-duration: 0.5s;
        }
      }
      .dropdown-menu {
        position: absolute;
        z-index: 1000;
        padding: 0;
        margin: 0;
        color: #212529;
        text-align: left;
        list-style: none;
        background-color: #fff;
        border-radius: 10px;
        width: 250px;
        flex-wrap: wrap;
        height: auto;
        top: 0;
        display: none;
        overflow: hidden;
        li {
          a {
            padding: 10px;
            margin: 0;
            width: 100%;
            font-size: 14px;
            height: auto;
            transition: all 0.2s;
            display: block;
            color: #303030;
            &:hover {
              background: #e9f0fc;
              color: #216dde;
            }
          }
        }
      }
      .nav_link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        font-weight: 500;
        color: #303030;
        gap: 5px;
      }
    }
  }
}
@keyframes down-animate {
  0% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.header {
  padding: 10px;
  .home-container {
    border: 1px solid #d1d9e0;
    border-radius: 10px;
    padding: 14px;
  }
  .navbar_collapse {
    display: flex;
    justify-content: center;
    height: 100%;
  }
  .navbar {
    &_wrp {
      &_left {
        max-width: 240px;
        width: 100%;
        gap: 0;
        a {
          img {
            max-width: 202px;
          }
        }
      }
      &_center {
        max-width: calc(100% - 480px);
        width: 100%;
        height: 100%;
      }
      &_right {
        max-width: 240px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        height: 100%;
      }
    }
  }
}
button {
  &.submit_btn {
    border-radius: 10px;
    background: $primary;
    font-size: 16px;
    padding: 8px 5px;
    border-bottom: 4px solid $primaryborder;
    color: #fff;
    font-weight: 700;
    width: 100px;
  }
  &.signup_btn {
    border-radius: 10px;
    font-size: 16px;
    padding: 8px 5px;
    border: 1px solid $primaryborder;
    color: #216dde;
    font-weight: 700;
    width: 100px;
  }
}